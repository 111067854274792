<template>
  <div>
    <div class="top">
      <div class="projectProgress">
        内部生产成本核算类型：<span class="progress">
          <el-radio :disabled="isShowTaskProject" v-model="isTaskProject" label="1"
            >基于项目组成员审核工时核算法</el-radio
          >
          <el-radio :disabled="isShowTaskProject" v-model="isTaskProject" label="2"
            >基于具体任务(或单价)核算法</el-radio
          ></span
        >
      </div>

      <div class="projectProgress" v-if="isTaskProject != 0">
        项目总进度：<span class="progress">{{ (allInfo.progress * 100).toFixed(1) }}%</span>
      </div>
    </div>
    <el-card v-show="isTaskProject == 2">
      <div slot="header">
        <span>项目最新进展</span>
        <template v-if="options.status != 36">
          <el-button
            class="add"
            @click="onAddTask"
            v-if="isBtn"
            style="margin-right: 8px"
            type="success"
            >新增任务</el-button
          >
          <!-- <el-button
            class="add"
            style="margin-right: 8px; background-color: #8b74f7c2"
            v-if="isAudit"
            @click="onAuditTask"
            type="success"
            >批量审核通过</el-button
          > -->
          <!-- <el-button
            class="add"
            style="margin-right: 8px"
            @click="reviewTaskDailog = true"
            type="primary"
            >历史分配任务</el-button
          > -->
        </template>
      </div>
      <el-table
        :data="unitPriceTableData"
        :summary-method="getSummariesUnitPrice"
        show-summary
        row-key="id"
        border
        ref="unitPriceTable"
        :row-class-name="tableRowClassName"
        :tree-props="{
          children: 'children',
          hasChildren: 'hasChildren',
        }"
        height="400"
      >
        <!-- @selection-change="handleSelectiOnChange"
        <el-table-column
          type="selection"
          fixed="left"
          :selectable="onSelectable"
          v-if="isAudit"
          :show-overflow-tooltip="false"
          width="55"
        >
        </el-table-column> -->

        <el-table-column
          label="任务名称"
          fixed="left"
          align="center"
          prop="taskName"
          min-width="200"
        >
        </el-table-column>
        <el-table-column label="任务类型" align="center" prop="isUnitPriceTask" min-width="120">
          <template slot-scope="scope">
            {{ !scope.row.isUnitPriceTask ? '基于具体任务' : '基于具体单价' }}
          </template>
        </el-table-column>
        <el-table-column label="任务价格" align="center" prop="taskPrice" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.taskPrice | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="任务进度" prop="taskProgress" min-width="80" align="center">
          <template slot-scope="scope"> {{ (scope.row.taskProgress * 100).toFixed(1) }}% </template>
        </el-table-column>
        <el-table-column label="任务单价" align="center" prop="taskUnitPrice" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.taskUnitPrice | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="计件单元" align="center" prop="quantityCompleted" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.quantityCompleted | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="任务审批工时(H)" align="center" prop="auditHour" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.auditHour | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="任务薪酬发放" align="center" prop="taskSalary" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.taskSalary | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="执行人" align="center" prop="executor" min-width="90">
        </el-table-column>
        <el-table-column label="创建人" align="center" prop="assigneeName" min-width="90">
        </el-table-column>
        <el-table-column label="任务所属部门" align="center" prop="department" min-width="110">
        </el-table-column>

        <el-table-column label="创建任务时间" prop="createdDate" min-width="105" align="center">
          <template slot-scope="scope">
            {{ scope.row.createdDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column label="预计完成时间" prop="completeDate" min-width="105" align="center">
          <template slot-scope="scope">
            {{ scope.row.completeDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column label="最新修改时间" prop="lastModifyDate" min-width="105" align="center">
          <template slot-scope="scope">
            {{ scope.row.lastModifyDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column label="是否完成" prop="isFlag" min-width="80" align="center">
          <template slot-scope="scope">
            {{ scope.row.isFlag == 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="任务状态" prop="isFlag" min-width="80" align="center">
          <template slot-scope="scope">
            {{ scope.row.isDisable == 0 ? '终止中' : '启用中' }}
          </template>
        </el-table-column>
        <el-table-column label="任务内容" align="center" prop="taskContent" min-width="200">
        </el-table-column>
        <!-- <el-table-column label="审批意见" align="center" prop="opinion" min-width="200">
        </el-table-column> -->
        <el-table-column
          label="操作"
          v-if="options.status != 36"
          fixed="right"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.parentId != 0 ||
                (scope.row.parentId == 0 && scope.row.children.length == 0)
              "
              type="text"
              size="small"
              @click="onReviewTask(scope.row.id)"
              class="text_Details_Bgc"
            >
              历史记录
            </el-button>
            <el-button
              type="text"
              v-if="isBtn && scope.row.parentId == 0"
              size="small"
              :style="{ 'background-color': scope.row.isDisable == 1 ? '#562727' : '#48cb38' }"
              @click="onUnitPriceDisable(scope.row)"
              class="text_Remove_Bgc"
            >
              {{ scope.row.isDisable == 1 ? '终止' : '启用' }}
            </el-button>
            <template v-if="scope.row.isDisable == 1">
              <el-button
                type="text"
                v-if="
                  isBtn ||
                  scope.row.parentId != 0 ||
                  (scope.row.parentId == 0 && scope.row.children.length == 0)
                "
                size="small"
                @click="onAddTask(scope.row)"
                class="text_Edit_Bgc"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                v-if="isBtn && scope.row.parentId == 0 && scope.row.isProcess == 0"
                size="small"
                @click="onUnitPriceDetele(scope.row.id)"
                class="text_Remove_Bgc"
              >
                删除
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-card v-show="isTaskProject == 1">
      <div slot="header">
        <span>项目最新进展</span>
        <el-button
          class="add"
          v-if="options.status != 36 && isBtn"
          @click="onAddAuditHour"
          type="success"
          >新增进度</el-button
        >
      </div>
      <el-table :data="auditHourTableData" row-key="id" height="400" border>
        <el-table-column align="center" type="index" label="序号" width="70"> </el-table-column>
        <el-table-column label="记录时间" align="center" prop="monthly" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.monthly | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column label="总进度" prop="progress" min-width="100" align="center">
          <template slot-scope="scope"> {{ scope.row.progress * 100 }}% </template>
        </el-table-column>

        <el-table-column
          label="本月完成工作内容"
          align="center"
          prop="completedWork"
          min-width="200"
        >
        </el-table-column>
        <el-table-column label="剩余工作内容" align="center" prop="remainingWork" min-width="200">
        </el-table-column>
        <el-table-column label="创建人" align="center" prop="createName" min-width="90">
        </el-table-column>
        <el-table-column label="操作" v-if="options.status != 36" align="center" width="70">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="onAddAuditHour(scope.row)"
              class="text_Edit_Bgc"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <OpinionArea ref="opinionArea" :procInstId="processInstId" :title="'意见区'"></OpinionArea>
    <!-- 常规 -->
    <EditDialog :isShow.sync="auditHourDailog" v-if="auditHourDailog">
      <template v-slot:title>
        <span style="color: #409eff">{{ auditHourForm.id ? '编辑' : '新增' }}常规进度</span>
      </template>
      <template v-slot:content>
        <el-form
          v-loading="loading"
          label-width="8em"
          ref="auditHourForm"
          :model="auditHourForm"
          :rules="auditHourRules"
        >
          <el-form-item
            label="总进度"
            style="width: 49%"
            :rules="getValidationRules('进度', true)"
            prop="progress"
          >
            <el-input style="width: 60px" v-model="auditHourForm.progress"></el-input>%
          </el-form-item>
          <el-form-item label="选择日期" style="width: 49%" prop="monthly">
            <el-date-picker
              value-format="timestamp"
              type="date"
              :clearable="false"
              v-model="auditHourForm.monthly"
              placeholder="请选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="本月完成工作" prop="completedWork">
            <el-input v-model="auditHourForm.completedWork" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="剩余工作" prop="remainingWork">
            <el-input v-model="auditHourForm.remainingWork" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button @click="onAuditHourSubmit" :loading="loading" type="success">{{
          auditHourForm.id ? '保存' : '提交'
        }}</el-button>
        <el-button type="info" plain :loading="loading" @click="auditHourDailog = false"
          >取消</el-button
        >
      </template>
    </EditDialog>
    <!-- 单价 -->
    <AssigningTask
      v-if="assigningDailog"
      :isShow.sync="assigningDailog"
      :options="assigningInfo"
      @onUpdate="init(options.id)"
    />
    <!-- 审核 -->
    <EditDialog :isShow.sync="auditDailog">
      <template v-slot:title>
        <span style="color: #409eff">任务分配审核</span>
      </template>
      <template v-slot:content>
        <el-form v-loading="loading" label-width="6em" ref="auditForm" :model="auditForm">
          <el-form-item label="审批意见" prop="opinion">
            <el-input v-model="auditForm.opinion" type="textarea"></el-input>
            <div v-show="auditIsShow && !auditForm.opinion" style="color: red">
              请输入审批不通过意见
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button @click="onAuditSubmit(1)" :loading="loading" type="success">通过</el-button>
        <el-button @click="onAuditSubmit(2)" :loading="loading" type="danger">不通过</el-button>
        <el-button type="info" plain :loading="loading" @click="auditDailog = false"
          >取消</el-button
        >
      </template>
    </EditDialog>
    <!-- 历史记录 -->
    <ReviewTask v-if="reviewTaskDailog" :isShow.sync="reviewTaskDailog" :projectId="reviewTaskId" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ProjectTask',
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
    AssigningTask: () => import('@/components/project/assigningTask.vue'),
    ReviewTask: () => import('@/components/project/reviewTask.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      unitPriceTableData: [],
      auditHourTableData: [],
      disabled: true,
      loading: false,
      assigningInfo: {},
      reviewTaskDailog: false,
      auditForm: {},
      auditDailog: false,
      assigningDailog: false,
      auditIsShow: false,
      idList: [],
      auditHourForm: {},
      auditHourDailog: false,
      auditHourRules: {
        monthly: [
          {
            required: true,
            message: '请选择日期',
            trigger: ['blur', 'change'],
          },
        ],
        completedWork: [
          {
            required: true,
            message: '请输入本月完成工作内容',
            trigger: ['blur', 'change'],
          },
        ],
        remainingWork: [
          {
            required: true,
            message: '请输入剩余工作内容',
            trigger: ['blur', 'change'],
          },
        ],
      },
      allInfo: {
        taskPrice: 0,
        projectProgress: 0,
        progress: 0,
      },
      isTaskProject: '0',
      processInstId: '',
      taskApprovalId: '',
      reviewTaskId: '',
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      originalForm: state => state.project.originalForm,
      projectInformation: state => state.project.projectInformation,
    }),
    isAudit() {
      let show = false
      if (this.unitPriceTableData && this.unitPriceTableData.length > 0) {
        show = this.unitPriceTableData[0].isAudit
      }
      return show
    },
    isBtn() {
      let show = false
      if (this.options && this.options.operatingButton) {
        show = this.options.operatingButton.some(r => {
          return r == 'EDIT'
        })
      }
      return show
    },
    isShowTaskProject() {
      let show = false
      if ((this.options.status == 30 && this.isBtn) || (this.options.status == 38 && this.isBtn)) {
        this.unitPriceTableData.forEach(item => (item.isPass = 0))
        show = false
      } else {
        show = true
      }
      return show
    },
  },
  watch: {
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (options) {
        if (options.id) {
          this.isTaskProject = options.isTaskProject
          this.init(options.id)
        }
      },
    },
  },
  created() {},
  filters: {
    applyAmount(n) {
      if (!n) return n
      return Number(n).toLocaleString()
    },
  },
  mounted() {},
  updated() {
    this.$nextTick(() => {
      if (this.$refs.unitPriceTable) {
        this.$refs.unitPriceTable.doLayout()
      }
    })
  },
  methods: {
    onReviewTask(id) {
      this.reviewTaskId = id
      this.reviewTaskDailog = true
    },
    // 提交任务
    async onSubmitTask() {
      if (this.isTaskProject == 0) {
        this.$message({
          type: 'warning',
          message: '请选择核算类型',
        })
        return
      }
      if (this.isTaskProject == 2) {
        if (this.unitPriceTableData.length <= 0) {
          this.$message({
            type: 'warning',
            message: '请添加具体任务(或单价)核算',
          })
          return
        }
      }
      const obj = {
        projectId: this.options.id,
        isTaskProject: this.isTaskProject,
      }
      let key = !this.taskApprovalId ? 'startProcess' : 'resubmitByReject'
      if (this.taskApprovalId) {
        obj.taskApprovalId = this.taskApprovalId
      }
      const res = await this.$api.project[key](obj)
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.refresh(1)
      }
    },

    onSelectable(row, idnex) {
      if (row.isPass > 0 || row.parentId != 0) {
        return false
      } else {
        return true
      }
    },
    // 批量审核
    onAuditTask() {
      if (this.idList.length <= 0) {
        this.$message({
          type: 'info',
          message: '请选择要审核的数据',
        })
        return
      }
      this.$confirm('是否批量审核多条数据通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$api.project.reviewTaskAssginBatch({ idList: this.idList })
          if (res.code == 200) {
            this.$message.success('审核成功')
            this.getUnitPrice(this.options.id)
            this.$emit('onUpdateRask')
          } else {
            this.$message.error('审核失败，请重新操作')
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消审核',
          })
        })
    },
    // 多选
    handleSelectiOnChange(val) {
      this.idList = val.map(item => item.id)
    },
    // 单审核
    async onAuditSubmit(index) {
      if (index == 2 && !this.auditForm.opinion) {
        this.auditIsShow = true
        return
      }
      this.auditForm.isPass = index
      this.loading = true
      const res = await this.$api.project.reviewTaskAssgin(this.auditForm)
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.getUnitPrice(this.options.id)
        this.$emit('onUpdateRask')
      }
      this.loading = false
      this.auditDailog = false
    },
    // 单价任务新增/编辑
    onAddTask(row = {}) {
      if (row.id) {
        this.assigningInfo = row.deepClone()
        this.assigningInfo.type = this.isBtn
        this.assigningInfo.taskProgress = this.assigningInfo.taskProgress * 100
      } else {
        this.assigningInfo = {
          projectId: this.options.id,
          completeDate: Date.now(),
          isUnitPriceTask: null,
          taskName: '',
          taskPrice: 0,
          taskProgress: 0,
          taskUnitPrice: 0,
          quantityCompleted: 0,
          departmentId: '',
          taskContent: '',
          executorList: [],
        }
      }
      this.assigningDailog = true
    },
    // 单价任务终止/启用
    onUnitPriceDisable(row) {
      this.$confirm(`确定${row.isDisable == 1 ? '终止' : '启用'}该单价任务吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$api.project.taskTerminationInterface({ id: row.id })
          if (res.code == 200) {
            this.$message.success('操作成功')
            this.getUnitPrice(this.options.id)
          } else {
            this.$message.error('操作失败，请重新操作')
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          })
        })
    },
    // 单价任务删除
    onUnitPriceDetele(id) {
      this.$confirm('确定删除该单价任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$api.project.xmTaskAssignDelete(id)
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.getUnitPrice(this.options.id)
            this.$emit('onUpdateRask')
          } else {
            this.$message.error('删除失败，请重新操作')
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 获取单价任务列表
    async getUnitPrice(projectId = '') {
      const res = await this.$api.project.projectTaskAssginList({ projectId })
      if (res.code == 200) {
        this.unitPriceTableData = res.data.xmTaskAssignResponseList || []
        this.allInfo.projectProgress = res.data.projectProgress || 0
        this.allInfo.progress = res.data.progress || 0
        this.allInfo.taskPrice = res.data.taskPrice || 0
        this.processInstId = res.data.processInstId
        this.taskApprovalId = res.data.taskApprovalId
      }
    },
    onAuitTask(row) {
      this.auditForm = {
        id: row.id,
        opinion: '',
      }
      this.auditIsShow = false
      this.auditDailog = true
    },
    // 常规任务编辑
    onAddAuditHour(row = {}) {
      if (row.id) {
        this.auditHourForm = row.deepClone()
        this.auditHourForm.progress = this.auditHourForm.progress * 100
      } else {
        this.auditHourForm = {
          projectId: this.options.id,
          monthly: Date.now(),
          progress: 0,
          completedWork: '',
          remainingWork: '',
        }
      }
      this.auditHourDailog = true
    },
    // 常规任务保存
    async onAuditHourSubmit() {
      this.$refs.auditHourForm.validate(async valid => {
        if (valid) {
          this.loading = true
          this.auditHourForm.progress = this.auditHourForm.progress / 100
          const res = await this.$api.project.addOrUpdateSchedule(this.auditHourForm)
          if (res.code == 200) {
            this.$message.success('操作成功')
          } else {
            this.$message.error('操作失败，请重新操作')
          }
          this.getAuditHour(this.options.id)
          this.loading = false
          this.auditHourDailog = false
        }
      })
    },
    // 获取常规任务列表
    async getAuditHour(projectId = '') {
      const res = await this.$api.project.projectScheduleList({ projectId })
      if (res.code == 200) {
        this.auditHourTableData = res.data.xmScheduleResponseList || []
        this.allInfo.projectProgress = res.data.projectProgress || 0
        this.allInfo.progress = res.data.progress || 0
        this.processInstId = res.data.processInstId
        this.taskApprovalId = res.data.taskApprovalId
      }
    },
    init(id) {
      // if (this.isTaskProject == 2) {
      //   } else if (this.isTaskProject == 1) {
      //     }
      this.getUnitPrice(id)
      this.getAuditHour(id)
      this.assigningDailog = false
      this.auditDailog = false
      this.auditHourDailog = false
    },
    getValidationRules(str = '', isShow = false) {
      return [
        {
          required: true,
          message: '请输入' + str,
          trigger: ['blur', 'change'],
        },
        {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: str + '必须为数值',
          trigger: ['blur', 'change'],
        },
        {
          validator: (rule, value, callback) => {
            if (!value && value != 0) {
              callback(new Error('请输入' + str))
            } else {
              const numericValue = parseFloat(value)
              if ((isShow && numericValue < 0) || (isShow && numericValue > 100)) {
                callback(new Error('进度必须在0到100之间'))
              } else {
                callback()
              }
            }
          },
          trigger: ['blur', 'change'],
        },
      ]
    },
    getSummariesUnitPrice(param) {
      const { columns, data } = param
      const sums = columns.map((column, index) => {
        if (index === 0) {
          return '合计'
        }
        const property = column.property
        if (!property) return ''
        const values = data.map(item => Number(item[property]))
        if (values.every(value => isNaN(value))) return ''

        if (index === 2) {
          return Number(this.allInfo.taskPrice.toFixed(2)).toLocaleString()
        } else if (index === 3) {
          return (this.allInfo.projectProgress * 100).toFixed(2) + '%'
        }

        return ''
      })
      return sums
    },
    tableRowClassName({ row, index }) {
      if (row.isPass === 2) {
        return 'finality'
      }
      return ''
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.top {
  display: flex;
  justify-content: space-between;
  .projectProgress {
    color: #000;
    font-size: 18px;
    .progress {
      font-size: 28px;
      font-weight: 900;
      color: green;
    }
  }
}

.add {
  float: right;
}
.text_Remove_Bgc {
  //删除
  background-color: #f78888;
  color: #fff;
  padding: 5px 5px;
}
.text_Edit_Bgc {
  //编辑
  background-color: #e6a23c;
  color: #fff;
  padding: 5px 5px;
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
.text_Examine_Bgc {
  //审批
  background-color: #8b74f7c2;
  color: #fff;
  padding: 5px 5px;
}
/deep/.el-table .finality {
  background: #f3cbc6c0;
}
// 移除hover时高亮效果
/deep/.el-table__body .el-table__row.hover-row td {
  background-color: transparent;
}
/deep/.el-table tbody tr:hover > td {
  background-color: transparent !important;
}
/deep/.el-table__fixed-right tbody tr:hover > td {
  background-color: transparent !important;
}
</style>
